:root {
  // Backgrounds
  --default-background: white;
  --secondary-background: var(--lightgrey100);
  --secondary-background-hover: var(--lightgrey300);
  --dark-background: var(--darkgrey900);
  --dark-background-hover: var(--darkgrey700);

  // Font colors
  --default-font: var(--darkgrey900);
  --light-font: white;
  --warning: orange;
  --error: crimson;
  --secondary-font: var(--darkgrey400);

  // Header
  --header-shadow: rgba(0, 0, 0, 0.3);

  // Sub Header
  --sub-header-background: radial-gradient(50% 2854.68% at 50% 50%, var(--purple800) 0%, var(--darkgrey900) 100%);

  // Sidebar
  --sidebar-focused-background: var(--darkgrey800);
  --sidebar-shadow: #00000052;
  --sidebar-inset-shadow: rgba(48, 50, 50, 0.28);

  // Tree Lines
  --tree-line-color: var(--darkgrey50);
}
