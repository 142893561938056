html {
  height: 100%;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  background: (--default-background);
}

main {
  position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span,
ul,
li {
  margin: 0;
  padding: 0;
}

mat-icon {
  font-family: 'Material Icons';
  color: var(--default-font);
  width: auto !important;
  height: auto !important;
}

mat-dialog-container {
  border-radius: 15px !important;
  padding: 0 !important;

  button {
    cursor: pointer;
  }
}

.d1r-dialog-container {
  border-radius: 15px;
  overflow: hidden;
}

.d1r-dialog-container-bo {
  mat-dialog-container {
    border-radius: 15px !important;
    overflow: visible;
  }
}

button {
  border: none;
  outline: none;
  cursor: pointer;

  &:disabled {
    cursor: default;
  }
}

mat-icon {
  svg {
    fill: var(--default-font);
  }
}
