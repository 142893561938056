@import 'libs/shared/util-styles/src/mixins';

// Siemens Sans Black
@font-face {
  font-family: 'Siemens Sans Black';
  src: url('/assets/fonts/siemenssans-black-webfont.woff2') format('woff2'), url('/assets/fonts/siemenssans-black-webfont.woff') format('woff'),
    url('/assets/fonts/SiemensSans-Black.otf');
}

// Siemens Slab Bold
@font-face {
  font-family: 'Siemens Slab Bold';
  src: url('/assets/fonts/SiemensSlab-Bold.ttf') format('ttf'), url('/assets/fonts/SiemensSlab-Bold.ttf') format('ttf'), url('/assets/fonts/SiemensSlab-Bold.ttf');
}

// Siemens Sans Roman
@font-face {
  font-family: 'Siemens Sans';
  src: url('/assets/fonts/siemenssans-roman-webfont.woff2') format('woff2'), url('/assets/fonts/siemenssans-roman-webfont.woff') format('woff'),
    url('/assets/fonts/SiemensSans-Roman.otf');
}

// Siemens Sans Serif
@font-face {
  font-family: 'Siemens Sans Serif';
  src: url('/assets/fonts/siemensserif-roman-webfont.woff2') format('woff2'), url('/assets/fonts/siemensserif-roman-webfont.woff') format('woff'),
    url('/assets/fonts/SiemensSerif-Roman.otf');
}

// Siemens Sans Bold
@font-face {
  font-family: 'Siemens Sans Bold';
  src: url('/assets/fonts/siemenssans-bold-webfont.woff2') format('woff2'), url('/assets/fonts/siemenssans-bold-webfont.woff') format('woff'),
    url('/assets/fonts/SiemensSans-Bold.otf');
}

// Material Icons
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/material-icons.woff2') format('woff2');
}

// Material Icons outlined
@font-face {
  font-family: 'Material Icons Outlined';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/material-icons-outlined.woff2') format('woff2');
}

/**
*  ====================== Fontstack ======================
*/
@mixin fontstack($typo: siemens-sans, $size: 16px, $line: 1, $letter: 0, $color: var(--darkgrey900), $weight: 400) {
  @if $typo == siemens-sans-black {
    font-family: 'Siemens Sans Black', Tahoma, Geneva, sans-serif;
  }

  @if $typo == siemens-slab-bold {
    font-family: 'Siemens Slab Bold', Tahoma, Geneva, sans-serif;
  }

  @if $typo == siemens-sans {
    font-family: 'Siemens Sans', Tahoma, Geneva, sans-serif;
  }

  @if $typo == siemens-sans-bold {
    font-family: 'Siemens Sans Bold', Tahoma, Geneva, sans-serif;
  }

  font-size: $size;
  line-height: $line;
  // letter-spacing: $letter; --> currently we check if customer app really needs letter-spacing, so this is not used
  font-weight: $weight;
  color: $color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: font-size 0.3s ease-in-out;
}

@mixin headline-intro($color) {
  @include fontstack(siemens-sans-black, 2rem, 2.5rem, 0.01rem, $color, 900);
}

@mixin headline-one($color) {
  @include fontstack(siemens-sans-black, 2.275rem, 2.2rem, 0.008rem, $color, 400);
}

@mixin headline-two($color) {
  @include fontstack(siemens-sans-bold, 1rem, 1.15rem, 0.01rem, $color, 400);
}

@mixin headline-three($color) {
  @include fontstack(siemens-sans-black, 1.15rem, 1.1rem, 0.01rem, $color, 400);

  @include mq(desktop-s-width) {
    @include fontstack(siemens-sans-black, 0.9rem, 1rem, 0.01rem, $color, 400);
  }
}

@mixin headline-four($color) {
  @include fontstack(siemens-sans-black, 1rem, 1.05rem, 0.01rem, $color, 400);
}

@mixin button-txt($color) {
  @include fontstack(siemens-sans-black, 0.75rem, 1.05rem, 0.02rem, $color, 400);
}

@mixin copy-txt($color) {
  @include fontstack(siemens-sans, 0.75rem, 1.05rem, 0.02rem, $color, 400);

  @include mq(desktop-m) {
    @include fontstack(siemens-sans, 0.6375rem, 1.05rem, 0.02rem, $color, 400);
  }
}

@mixin copy-txt-bold($color) {
  @include fontstack(siemens-sans-bold, 0.75rem, 1.05rem, 0.02rem, $color, 400);

  @include mq(desktop-m) {
    @include fontstack(siemens-sans-bold, 0.6375rem, 1.05rem, 0.02rem, $color, 400);
  }
}

@mixin copy-txt-small($color) {
  @include fontstack(siemens-sans, 0.7rem, 0.875rem, 0.02rem, $color, 400);
}

@mixin span-txt($color) {
  @include fontstack(siemens-sans-bold, 0.65rem, 1rem, 0.02rem, $color, 400);
}

@mixin content-txt($color: var(--darkgrey900)) {
  @include fontstack(siemens-sans, 0.875rem, 1.25rem, 0.02rem, $color, 400);
}
