/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 */

/* Lib Styles */
@import 'ngx-toastr/toastr';
@import '@ng-select/ng-select/themes/default.theme.css';

@import 'input-base';
@import 'styles/_global/colors';
@import 'styles/_global/typography';
@import 'styles/atoms/a-text';
@import 'styles/_global/materialicons';
@import 'styles/layout';
@import 'styles/_global/materialicons';
@import 'styles/_global/base';
